import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Success() {
    return (
         <div className="success-message">
             <div className="container">
                 <div className="row"><h1>Ďakujeme, Vaša správa bola odoslaná</h1></div>
                 <div className="row"><AniLink cover direction="right" to="/kontakt" bg="#000" duration={1}>Späť na stránku</AniLink></div>

             </div>
             
         </div>
    )
}